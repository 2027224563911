import WarningBox from '@applicationComponents/common/warning-box.vue'
import { useModal, useInstance, useStore } from 'skid-composables'

export const useWarningInfo = (step, cartItems, options = {}) => {
  const vueInstance = options.vueInstance || useInstance()

  const store = options.store || useStore()

  const call = () => {
    if (messages.length === 0) return
    useModal({
      parent: vueInstance,
      component: WarningBox,
      props: {
        info: Object.groupBy(messages, (message) => {
          return message.type || ''
        })
      }
    })
  }

  const variants = cartItems.map((cartItem) => {
    return store.getters['productVariants/find'](cartItem.variant_id)
  })

  const currentOrder = store.getters['orders/find'](
    store.getters['orderItems/currentOrderId']
  )

  let messages = variants
    .filter((variant) => {
      const steps = variant.warning_info?.steps || []

      if (step.includes('no_notes')) {
        return steps.includes(step) && !currentOrder.notes
      } else {
        return steps.includes(step)
      }
    })
    .map((variant) => {
      return {
        name: variant.name || variant.product_name,
        message: variant.warning_info.message,
        type: variant.warning_info.type
      }
    })
    .sort((a) => {
      return a.type ? -1 : 1
    })

  call()
}

export default useWarningInfo
