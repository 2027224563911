<template lang="pug">
.vc-common-warning-box
  .group(
    v-for="key in keys"
    :key="key"
  )
    .group-title(v-if="key") {{ key }}
    .content-box
      .inner-wrapper(v-for="message in info[key]")
        .title {{ message.name }}
        .warning-message {{ message.message }}
</template>

<script setup lang="ts">
const props = defineProps({
  info: {
    type: Object,
    required: true
  }
})

const keys = Object.keys(props.info)
</script>
