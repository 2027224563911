<template>
  <div v-if="promoCodeUsable" class="vc-order-promo-code-checker">
    <b-field
      class="code-field"
      :label="attributeLocaleText('order', 'coupon_code')"
      :type="validateResultType"
      :message="errors.get('coupon_code')">
      <b-field custom-class="code-field" :type="validateResultType">
        <b-input
          type="text"
          :placeholder="messageLocaleText('enter_promo_code')"
          v-model="promoCode"
          @input="resetStatus"
          @keyup.enter.native="checkPromoCode"
          expanded />
        <div class="control">
          <a
            class="button is-small is-info"
            @click="checkPromoCode"
            :disabled="!isValidPromoCodeLength">
            {{ actionLocaleText('use_promo_code') }}
          </a>
        </div>
      </b-field>
    </b-field>

    <div class="applied-coupons" v-if="appliedCoupons.length > 0">
      <coupon-unit
        v-for="(coupon, index) in appliedCoupons"
        :key="index"
        :coupon="coupon" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'skid-composables'
import CouponUnit from './coupon-unit.vue'
import { computed, ref } from 'vue'

const props = defineProps<{ order: Object }>()

const store = useStore()

const promoCode = ref<string>('')
const isPromoCodeChecked = ref<boolean>(false)
const isPromoCodeValid = ref<boolean>(false)

const errors = computed(() => store.getters['users/errors'])
const isValidPromoCodeLength = computed(() => {
  return promoCode.value.length >= 4
})
const validateResultType = computed(() => {
  return {
    'is-danger': isPromoCodeChecked.value && !isPromoCodeValid.value,
    'is-success': isPromoCodeChecked.value && isPromoCodeValid.value
  }
})
const promoCodeUsable = computed(() => {
  return true
})
const appliedCoupons = computed(() => {
  return props.order.coupons
})

function checkPromoCode() {
  if (!isValidPromoCodeLength.value) return

  store
    .dispatch('users/applyCoupon', promoCode.value)
    .then((_) => {
      isPromoCodeValid.value = true
      isPromoCodeChecked.value = true
    })
    .catch((_) => {
      isPromoCodeValid.value = false
      isPromoCodeChecked.value = true
    })
}

function resetStatus() {
  errors.value.clear('coupon_code')
  isPromoCodeChecked.value = false
  isPromoCodeValid.value = false
}
</script>
